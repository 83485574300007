<template>
  <div class="templateEdit">
    <template v-if="loading">
      <LoadingPlaceholder />
    </template>
    <template v-else>
      <template v-if="template">
        <div class="row">
          <div class="col-12">
            <label class="font-weight-bold">{{ $t('name') }}</label>
            <input
              v-model="template.name"
              v-focus
              :class="['form-control mb-4 m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Name') }]"
              placeholder="Name"
              type="text"
            >
            <span
              v-show="errors.has('Name')"
              class="badge badge-danger"
            >{{ errors.first('Name') }}</span>
          </div>
          <div class="col-12">
            <label class="font-weight-bold">{{ $t('installationType') }}</label>
            <select
              v-model="template.installationTypeId"
              :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('InstallationTypeId') }]"
            >
              <option
                v-for="(type, index) in types"
                :key="'option'+index"
                :value="type.id"
              >
                <span>{{ type.name }}</span>
              </option>
            </select>
            <span
              v-show="errors.has('InstallationTypeId')"
              class="badge badge-danger"
            >{{ errors.first('InstallationTypeId') }}</span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <button
              v-if="readyToAdd"
              class="btn btn-primary float-right"
              @click="updateTemplate()"
            >
              <font-awesome-icon
                class="mr-1"
                icon="upload"
              />
              <span>
                {{ $t('save') }}
              </span>
            </button>
            <button
              v-else
              disabled
              class="btn btn-primary float-right"
            >
              <font-awesome-icon
                class="mr-1"
                icon="upload"
              />
              <span>
                {{ $t('save') }}
              </span>
            </button>
            <div class="clearfix" />
          </div>
        </div>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js'

export default {
  name: 'TemplateEdit',
  mixins: [
    errorMixin
  ],
  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      template: null,
      types: null
    }
  },
  computed: {
    readyToAdd: function () {
      if (this.template.installationTypeId && this.types && this.template.name) return true;
      return false;
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    getData () {
      this.loading = true;
      this.axios.all([
        this.axios.get('/Installation/GetInstallationTypes'),
        this.axios.get(`/SmsManagement/GetTemplate?templateId=${ this.templateId }`)
      ]).then(
        this.axios.spread((typesResponse, templateResponse) => {
          this.types = typesResponse.data;
           let allTypes = {
            id: -2,
            jiraComponent: 'All Installation Types',
            name: 'All Installation Types'
          };
          this.types.unshift(allTypes);

          let customerNotificationType = {
            id: -1,
            jiraComponent: 'Customer Notification',
            name: 'Customer Notification'
          };
          this.types.unshift(customerNotificationType);
          this.template = templateResponse.data;
          if(this.template.installationTypeId == -1) {
            this.template.installationType = 'Customer Notification';
          }
          if(this.template.installationTypeId == -2) {
            this.template.installationType = 'All Installation Types';
          }
        }
      ))
      .finally(() => {
        this.loading = false;
      });
    },
    updateTemplate () {
      this.loading = true;
      let tmpTemplate = {
        'Name': this.template.name,
        "InstallationTypeId": this.template.installationTypeId,
        "Id": this.templateId,
        "InstallationType": this.template.installationType
      };
      this.axios.post('/SmsManagement/UpdateTemplate', tmpTemplate)
        .then(() => {
          this.error_clear();
          this.$emit("reloadTemplates");
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>