<template>
  <div class="smsTemplateList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(templates)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(templates)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="Filter by Id"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="typeSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in installationTypes"
              :key="`deviceSearch-typeSelectOption-${key}`"
              :value="type"
              :selected="kgm_selectDefaultOption(props, type, 'typeSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('typeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editTemplate(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <router-link
                :to="`/smsmanagement/template/${ props.dataItem.id }`"
                class="btn btn-primary btn-sm"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="eye"
                  style="vertical-align: middle;"
                />
                <span>Messages</span>
              </router-link>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmRemoveModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        :show-sidebar="showSidebar"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          :template-id="currentId"
          @reloadTemplates="reloadTemplatesAndCloseSidebar"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete template?"
      class="overflowHidden"
    >
      <p>Are you sure you want to delete this template?</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeTemplate()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';
import AddTemplate from '@/components/SmsManagement/AddTemplate.vue';
import TemplateEdit from '@/components/SmsManagement/TemplateEdit.vue';

import { SweetModal, SweetModalTab } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: 'SmsTemplateList',
  components: {
    Sidebar,
    AddTemplate,
    TemplateEdit,
    SweetModal,
    SweetModalTab
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      templates: null,
      message: null,
      showSidebar: false,
      currentComponent: null,
      currentId: null,
      indexToDelete: null,
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_columns: [
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: 'Id',
          filterCell: 'idFilter',
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter"
        },
        {
          field: 'installationType',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: 'typeFilter'
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.templates')
    }
  },
  computed: {
    installationTypes () {
      if (!this.templates) return [ "" ];
      //Distinct (include every entry only once)
      let installationTypes = Array.from(new Set(this.templates.map(o=>o.installationType)));
      //Remove null values
      installationTypes = installationTypes.filter((e) => {return e});
      return installationTypes.sort();
    },
    messageCount: function () {
      if (!this.templates) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let messageCount = Array.from(new Set(this.templates.map(o => o.messageCount)));
      return messageCount.sort();
    }
  },
  created () {
    this.getAllTemplates();

    this.$eventBus.$on("removeTemplate", this.openConfirmRemoveModal);
    this.$eventBus.$on("editTemplate", this.editTemplate);
  },
  beforeDestroy () {
    this.$eventBus.$off("removeTemplate", this.openConfirmRemoveModal);
    this.$eventBus.$off("editTemplate", this.editTemplate);
  },
  methods: {
    reloadTemplatesAndCloseSidebar () {
      this.hideSidebar();
      this.getAllTemplates();
      this.$emit("reloadAuditLogs");
    },
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    hideSidebar () {
      this.showSidebar = false;
      this.currentId = null;
      this.currentComponent = null;
    },
    showAddTemplate () {
      this.currentComponent = "AddTemplate";
      this.show();
    },
    editTemplate (emitValues) {
      this.currentId = emitValues.toString();
      this.currentComponent = "TemplateEdit";
      this.show();
    },
    //#endregion
    //#region API-calls
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    removeTemplate () {
      this.loading = true;
      this.axios.delete(`/SmsManagement/RemoveTemplate?templateId=${ this.indexToDelete }`)
        .then(() => {
          this.getAllTemplates();
          this.$emit("reloadAuditLogs");
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
          this.loading = false;
        });
    },
    getAllTemplates () {
      this.loading = true;
      this.axios.get('/SmsManagement/GetAllTemplates')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.templates = response.data;
          this.templates.forEach(e => {
            if(e.installationTypeId == -1) {
              e.installationType = 'Customer Notification';
            }
            if(e.installationTypeId == -2) {
              e.installationType = 'All Installation Types';
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
    //#endregion
  }
}
</script>
<style>
.smsTemplateList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>